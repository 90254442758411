<template>
  <div>
    <loading v-if="loading" />

    <div v-else-if="error">
      <no-results
        :cta="{ label: 'Try again', class: 'is-warning' }"
        icon="exclamation-triangle"
        title="Oops, something went wrong!"
        message="There was a problem retrieving file backups for this site; please try again and let us know if the problem persists."
        @click="getFileConfig"
      />
    </div>

    <div v-else-if="!hasConfig">
      <no-results
        :cta="{ label: 'Configure now', class: 'is-success' }"
        icon="cog"
        title="Not configured"
        message="File backups have not been configured."
        @click="configureFileBackup"
      />
    </div>

    <div v-else>
      <b-message v-if="!config.snapshot.length" type="is-info">
        <p>
          <b-icon icon="info-circle" size="is-small" />There are no backups to
          show just yet – probably because we're still processing the first one;
          this can take some time depending on the size of your site.
          <strong>Check back shortly to see your first snapshot</strong>.
        </p>
      </b-message>

      <b-table :data="[config]" :loading="processing">
        <template slot-scope="props">
          <b-table-column field="id" label="Status">
            <status-label
              :status="
                props.row.deleted ? 'backup-deleted' : props.row.backupstatus
              "
            />
          </b-table-column>

          <b-table-column field="host" label="Host">{{
            isSSH ? `${props.row.sshhost} (SSH)` : `${props.row.ftphost} (FTP)`
          }}</b-table-column>

          <b-table-column field="lastbackup" label="Last Backup" width="160">
            <span v-if="props.row.lastbackup">{{
              $moment(new Date(props.row.lastbackup)).from()
            }}</span>
            <span v-else>–</span>
          </b-table-column>

          <b-table-column label="Snapshots" width="140">
            <a
              v-if="props.row.snapshot.length"
              class="has-text-dark"
              @click="viewFileSnapshots(props.row.snapshot)"
            >
              <u>{{ props.row.snapshot.length }} snapshot(s)</u>
            </a>
            <span v-else>–</span>
          </b-table-column>

          <b-table-column label width="1" class="has-text-right">
            <b-dropdown position="is-bottom-left" class="has-text-left">
              <a slot="trigger" class="has-text-dark">
                <b-icon icon="cog" />
              </a>

              <b-dropdown-item
                v-if="
                  !props.row.deleted &&
                    !props.row.deleteforgdpr &&
                    props.row.backupstatus !== 'inprogress' &&
                    props.row.forcebackupnow === 0
                "
                @click="backupNow()"
                >Backup now</b-dropdown-item
              >

              <b-dropdown-item
                v-if="allowEdit(props.row)"
                @click="configureFileBackup()"
                >Edit</b-dropdown-item
              >

              <b-dropdown-item v-if="user.isAgent" @click="showLogs"
                >Logs</b-dropdown-item
              >

              <b-dropdown-item
                v-if="!props.row.deleted && !props.row.deleteforgdpr"
                class="has-text-danger"
                @click="deleteFileConfig()"
                >Delete</b-dropdown-item
              >

              <b-dropdown-item
                v-if="props.row.deleted || props.row.deleteforgdpr"
                @click="restoreFileConfig()"
                >Undelete</b-dropdown-item
              >
            </b-dropdown>
          </b-table-column>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileBackups",
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      processing: false,
      error: false,
      config: {}
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]();
    },
    isAgent() {
      return this.$store.getters["user/isAgent"]();
    },
    hasConfig() {
      return this.config.fqdn;
    },
    isSSH() {
      return ["sftp", "rsync"].includes(this.config.accessmethod);
    }
  },
  created() {
    this.getFileConfig();
  },
  methods: {
    allowEdit(row) {
      if (this.isAgent) return true;
      return (
        !row.deleted &&
        !row.deleteforgdpr &&
        row.backupstatus !== "inprogress" &&
        row.forcebackupnow === 0
      );
    },
    showLogs() {
      this.$modal.open({
        component: () => import("@shared/backups/_fileBackupsNotesModal"),
        parent: this,
        width: 720,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          config: this.config
        }
      });
    },
    backupNow() {
      this.processing = true;
      this.$store
        .dispatch("sites/backups/api", {
          type: "force-backup-now",
          siteId: this.siteId
        })
        .then(() => {
          this.$toast.open({
            message: "Backup requested",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(() => {
          this.$toast.open({
            message: "There was a problem requesting a backup",
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
          this.getFileConfig();
        });
    },
    getFileConfig() {
      this.processing = true;
      this.error = false;
      this.$store
        .dispatch("sites/backups/api", {
          type: "get-file-config",
          siteId: this.siteId
        })
        .then(response => {
          this.$set(this, "config", response);
        })
        .catch(() => {
          this.$toast.open({
            message: "Error retrieving backup config",
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
          this.processing = false;
        });
    },
    configureFileBackup() {
      const modal = this.$modal.open({
        component: () => import("@shared/backups/_fileConfigModal"),
        parent: this,
        width: 640,
        hasModalCard: true,
        canCancel: ["outside", "escape"],
        props: {
          siteId: this.siteId,
          config: this.config
        },
        events: {
          refresh: () => {
            this.getFileConfig();
            modal.close();
          }
        }
      });
    },
    restoreFileConfig() {
      this.processing = true;
      this.$store
        .dispatch("sites/backups/api", {
          type: "restore-file-config",
          siteId: this.siteId
        })
        .then(() => {
          this.getFileConfig();
          this.$toast.open({
            message: "Backup configuration restored",
            position: "is-bottom",
            queue: false
          });
        })
        .catch(() => {
          this.$toast.open({
            message: "There was a problem undeleting your configuration",
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.processing = false;
        });
    },
    deleteFileConfig() {
      const confirm = this.$confirm.open({
        parent: this,
        props: {
          autoClose: false,
          action: "Delete",
          message: `Are you sure you want to delete this backup configuration?`
        },
        canCancel: [],
        events: {
          confirmed: async () => {
            await this.onDeleteConfirmed();
            confirm.close();
          }
        }
      });
    },
    onDeleteConfirmed() {
      return this.$store
        .dispatch("sites/backups/api", {
          type: "delete-file-config",
          siteId: this.siteId
        })
        .then(result => {
          this.getFileConfig();
          this.$toast.open({
            message: result.message,
            position: "is-bottom",
            queue: false
          });
        })
        .catch(() => {
          this.processing = false;
          this.$toast.open({
            message: "There was a problem deleting your configuration",
            position: "is-bottom",
            queue: false,
            type: "is-danger"
          });
        });
    },
    viewFileSnapshots(snapshots = []) {
      this.$modal.open({
        component: () => import("@shared/backups/_fileSnapshotsModal"),
        parent: this,
        width: 840,
        hasModalCard: true,
        canCancel: ["outside"],
        props: {
          siteId: this.siteId,
          snapshots
        }
      });
    }
  }
};
</script>
